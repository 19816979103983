var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"absolute":"","color":"primary","dark":"","shrink-on-scroll":"","prominent":"","src":"https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80","fade-img-on-scroll":"","scroll-target":"#scrolling-techniques-3"},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({attrs:{"gradient":"to top right, rgba(59, 179, 97, 0.7), rgba(59, 179, 97, 0.7)"}},'v-img',props,false))]}},{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.current_tab),callback:function ($$v) {_vm.current_tab=$$v},expression:"current_tab"}},[_c('v-tab',{key:0,on:{"click":function () {
              _vm.$router.replace('/d');
            }}},[_vm._v("Dashboard")]),_vm._l((_vm.tab_clients),function(client){return _c('v-tab',{key:client.value,on:{"click":function($event){return _vm.triggerTabClickClient(client.value)}}},[_vm._v(_vm._s(client.text))])}),_c('v-tab',{attrs:{"text":""},on:{"click":_vm.openAddClient}},[_c('i',{staticClass:"fad fa-plus"}),_vm._v("new")])],2)]},proxy:true}])},[_c('v-app-bar-title',{staticStyle:{"margin-left":"50px","width":"100%"}},[_vm._v("Timeful")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function () {
          _vm.$emit('openProfile');
        }}},[_c('i',{staticClass:"fal fa-user"})]),_c('v-btn',{staticStyle:{"margin-right":"20px"},attrs:{"icon":""},on:{"click":function () {
          _vm.$emit('openEnterprises');
        }}},[_c('i',{staticClass:"fal fa-building"})]),_c('v-select',{staticStyle:{"margin-top":"7px"},attrs:{"items":_vm.select_enterprises,"item-text":"text","item-value":"value","placeholder":"Enterprise","no-data-text":"No enterprises yet"},on:{"change":_vm.triggerSelectEnterprise},model:{value:(_vm.enterprise),callback:function ($$v) {_vm.enterprise=$$v},expression:"enterprise"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }