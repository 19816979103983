<template>
  <div>
    <v-dialog v-model="show" max-width="600px">
      <v-card>
        <v-card-title>Enterprises</v-card-title>
        <v-card-text>
          <v-data-table
            :items="tab_enterprises"
            :headers="[
              {
                text: 'Name',
                value: 'name',
              },
              {
                text: 'Archived',
                value: 'archived',
                width: '30%',
              },
            ]"
            @click:row="(item) => { enterprise_dialog = true; enterprise_dialog_item = { id: item.id, name: item.name, archived: item.archived === '1' } }"
          >
            <template v-slot:[`item.archived`]="{ item }">
              <i
                class="fas fa-circle"
                :style="{ color: item.archived ? 'grey' : 'green' }"
              ></i
              ><span style="margin-left: 10px">{{
                !item.archived ? "Active" : "Archived"
              }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            type="button"
            @click="show = false"
            style="margin-top: 10px"
            >close</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            type="button"
            @click="enterprise_add_dialog = true"
            style="margin-top: 10px"
            >add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <EditEnterpriseDialog
      v-model="enterprise_dialog"
      :currentItem="enterprise_dialog_item"
    ></EditEnterpriseDialog>
    <AddEnterpriseDialog v-model="enterprise_add_dialog"></AddEnterpriseDialog>
  </div>
</template>

<script>
import { storage } from "../schmucklicloud";
import EditEnterpriseDialog from "../components/EditEnterpriseDialog";
import AddEnterpriseDialog from "../components/AddEnterpriseDialog";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enterprises: [],

      enterprise_dialog: false,
      enterprise_dialog_item: undefined,
      enterprise_add_dialog: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    tab_enterprises() {
      var table = [];

      this.enterprises.forEach(function (item) {
        table.push({
          id: item.id,
          name: item.data.name,
          archived: item.data.archived || false,
        });
      });

      return table;
    },
  },
  watch: {
    show(open) {
      if (open) {
        this.loadEnterprises();
      }
    },
    enterprise_dialog(open) {
      if (!open) {
        this.loadEnterprises();
      }
    }
  },
  mounted() {
    this.loadEnterprises();
  },
  methods: {
    async loadEnterprises() {
      var response = await storage.getAll("enterprise");
      if (response.isOK) {
        this.enterprises = response.data || [];
      } else {
        this.$notify.toast(response.message);
      }
    }
  },
  components: {
    EditEnterpriseDialog,
    AddEnterpriseDialog,
  },
};
</script>

<style>
</style>