<template>
  <div>
    <v-dialog v-model="show" max-width="600px">
      <v-form @submit="editItem">
        <v-card>
          <v-card-title
            >Edit: {{ currentItem.name }}<v-spacer></v-spacer
            ><v-btn
              @click="deleteItem"
              text
              type="button"
              color="red"
              style="margin-top: 10px"
              ><i class="fal fa-trash"></i
            ></v-btn>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap style="margin: 0">
              <v-flex xs12>
                <v-text-field
                  label="Enterprise name"
                  v-model="edit_enterprise__name"
                  placeholder="ex. Google"
                ></v-text-field>
                <v-switch
                  v-model="edit_enterprise__archived"
                  label="Archived"
                ></v-switch>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              type="button"
              @click="show = false"
              style="margin-top: 10px"
              >cancel</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn text type="submit" color="primary" style="margin-top: 10px"
              >edit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { storage } from "../schmucklicloud";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currentItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      edit_enterprise__name: "",
      edit_enterprise__archived: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async editItem(e) {
      e.preventDefault();

      var response = await storage.update("enterprise", this.currentItem.id, {
        name: this.edit_enterprise__name,
        archived: this.edit_enterprise__archived ? "1" : "",
      });

      if (response.isOK) {
        this.show = false;
        this.$emit("edited");

        this.$notify.toast("The enterprise has been updated.");

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        this.$notify.toast(response.message);
      }
    },
    async deleteItem() {
      if (confirm("Do you really want to delete this enterprise?")) {
        var response = await storage.delete("enterprise", this.currentItem.id);
        if (response.isOK) {
          this.show = false;
          this.$notify.toast("The enterprise has been deleted.");
          this.$emit("deleted", this.currentItem.id);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          this.$notify.toast(response.message);
        }
      }
    },
  },
  watch: {
    show(open) {
      if (open) {
        this.edit_enterprise__name = this.currentItem.name;
        this.edit_enterprise__archived = this.currentItem.archived;
      }
    },
  },
};
</script>

<style>
</style>