<template>
  <div>
    <v-app-bar
      absolute
      color="primary"
      dark
      shrink-on-scroll
      prominent
      src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
      fade-img-on-scroll
      scroll-target="#scrolling-techniques-3"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(59, 179, 97, 0.7), rgba(59, 179, 97, 0.7)"
        ></v-img>
      </template>

      <v-app-bar-title style="margin-left: 50px; width: 100%"
        >Timeful</v-app-bar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="
          () => {
            $emit('openProfile');
          }
        "
      >
        <i class="fal fa-user"></i>
      </v-btn>
      <v-btn
        icon
        style="margin-right: 20px"
        @click="
          () => {
            $emit('openEnterprises');
          }
        "
      >
        <i class="fal fa-building"></i>
      </v-btn>

      <v-select
        style="margin-top: 7px"
        v-model="enterprise"
        @change="triggerSelectEnterprise"
        :items="select_enterprises"
        item-text="text"
        item-value="value"
        placeholder="Enterprise"
        no-data-text="No enterprises yet"
      ></v-select>

      <template v-slot:extension>
        <v-tabs align-with-title v-model="current_tab">
          <v-tab
            :key="0"
            @click="
              () => {
                $router.replace('/d');
              }
            "
            >Dashboard</v-tab
          >
          <!-- Clients -->
          <v-tab
            v-for="client in tab_clients"
            :key="client.value"
            @click="triggerTabClickClient(client.value)"
            >{{ client.text }}</v-tab
          >
          <v-tab text @click="openAddClient"
            ><i class="fad fa-plus"></i>new</v-tab
          >
        </v-tabs>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import { storage } from "../schmucklicloud";

export default {
  data() {
    return {
      enterprises: [],
      enterprise: undefined,

      current_tab: 0,

      clients: [],
      client: undefined,

      today_records: [],

      open_profile: false,
    };
  },
  computed: {
    select_enterprises() {
      var temp_enterprises = [];
      if (this.enterprises.length === 0) {
        return temp_enterprises;
      }
      this.enterprises.forEach(function (enterprise) {
        temp_enterprises.push({
          text: enterprise.data.name,
          value: enterprise.id,
        });
      });
      return temp_enterprises;
    },
    tab_clients() {
      var temp_clients = [];

      if (this.clients.length === 0) {
        return temp_clients;
      } else {
        this.clients.forEach(function (client) {
          temp_clients.push({
            text: client.data.name,
            value: client.id,
          });
        });
      }
      return temp_clients;
    },
  },
  mounted() {
    this.loadEnterprises();
  },
  watch: {
    $route(route) {
      if (route.params.client_id) {
        this.current_tab = this.getTabIdByClientId(
          parseInt(route.params.client_id)
        );
      } else if (route.path === "/d") {
        this.current_tab = 0;
      }
    },
  },
  methods: {
    async loadEnterprises() {
      var response = await storage.get("enterprise", [
        {
          column: "archived",
          operator: "==",
          value: "",
        },
      ]);
      if (response.isOK) {
        this.enterprises = response.data || [];

        var enterprise_id = window.localStorage.getItem("enterprise");
        if (enterprise_id !== undefined) {
          this.selectEnterprise(parseInt(enterprise_id));
        } else {
          if (this.enterprises.length > 0) {
            this.selectEnterprise(this.enterprises[0].id);
          }
        }
      } else {
        this.$notify.toast(response.message);
      }
    },
    triggerSelectEnterprise() {
      window.localStorage.setItem("enterprise", this.enterprise);
      this.selectEnterprise(this.enterprise);
    },
    openAddClient() {
      this.$router.replace("/d/add");
    },
    selectEnterprise(enterprise_id) {
      this.enterprise = enterprise_id;
      this.loadClients(enterprise_id);
    },

    async loadClients(enterprise_id) {
      var response = await storage.get(
        "clients",
        [
          {
            column: "archived",
            operator: "==",
            value: "",
          },
          {
            column: "enterprise_id",
            operator: "==",
            value: enterprise_id,
          },
        ],
        {
          column: "name",
          order: "asc",
        }
      );
      if (response.isOK) {
        this.clients = response.data || [];

        var client_id = this.$route.params.client_id;
        if (client_id !== undefined) {
          console.log(this.getTabIdByClientId(client_id));
          this.current_tab = this.getTabIdByClientId(client_id);
        }
      }
    },
    triggerTabClickClient(client_id) {
      this.$router.replace("/d/" + client_id);
    },

    getTabIdByClientId(client_id) {
      var tabindex = -1;
      this.tab_clients.forEach(function (client, index) {
        if (client.value === parseInt(client_id)) {
          tabindex = index + 2; // + 1 for the Overview page
        }
      });
      return tabindex;
    },
  },
};
</script>

<style>
.v-app-bar-title__content {
  width: 100% !important;
  text-align: left;
}
</style>