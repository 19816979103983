import { sCAuth } from "schmucklicloud_auth";
import { sCStorage } from "schmucklicloud_storage";

const APP_ID = "a4c4d38ca6d7a4a3699b0cdef840da61cf4c3c12";
const APP_SECRET = "ac62a0d04855e2cc40c07c188cc9f1df34b5f5e1b723333a0fb0a78ed09e86ad";

var auth = new sCAuth(APP_ID, APP_SECRET);
var storage = new sCStorage(APP_ID, APP_SECRET);

storage.setBucket(29);

var session_token = window.localStorage.getItem("_session_token");
storage.setAuthToken(session_token);

export { auth, storage };