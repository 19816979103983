<template>
  <v-card hover :color="color" :loading="loading" class="tile">
    <v-card-title style="padding-bottom: 0"
      ><i v-if="icon !== ''" :class="{ fad: true, [icon]: true }"></i
      >{{ value == 0 ? "..." : value }}</v-card-title
    >
    <v-card-text>{{ name }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: "...",
    },
    name: {
      type: String,
      default: "...",
    },
    color: {
      type: String,
      default: "",
      required: false,
    },
    icon: {
      type: String,
      default: "",
      required: false,
    },
  },
};
</script>

<style>
.tile {
  margin: 5px;
}
</style>