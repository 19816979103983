import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/activate',
    name: 'Activate',
    component: () => import('../views/Activate.vue')
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/d',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/d/add',
    name: 'AddClient',
    component: () => import('../views/AddClient.vue')
  },
  {
    path: '/d/:client_id',
    name: 'Client',
    component: () => import('../views/ClientView.vue')
  },
  {
    path: '/d/:client_id/:project_id',
    name: 'ClientProject',
    component: () => import('../views/ClientView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
