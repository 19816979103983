<template>
  <v-app>
    <v-card flat class="overflow-hidden">
      <Header @openProfile="open_profile = true" @openEnterprises="open_enterprises = true"></Header>
      <v-sheet
        id="scrolling-techniques-3"
        class="overflow-y-auto"
        max-height="100%"
        >-->
        <v-container style="padding-top: 220px; text-align: left">
          <router-view />
        </v-container>
      </v-sheet>
    </v-card>
    <footer>&copy; {{ new Date().getFullYear() }} by schmuckli.dev</footer>
    <ProfileDialog v-model="open_profile"></ProfileDialog>
    <EnterpriseDialog v-model="open_enterprises"></EnterpriseDialog>
  </v-app>
</template>

<script>
import Header from "./components/Header";
import ProfileDialog from "./components/ProfileDialog";
import EnterpriseDialog from "./components/EnterpriseDialog";

export default {
  data() {
    return {
      open_profile: false,
      open_enterprises: false
    };
  },
  components: {
    Header,
    ProfileDialog,
    EnterpriseDialog
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
footer {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
