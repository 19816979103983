<template>
  <div>
    <v-dialog width="500px" v-model="show">
      <v-form @submit="addEnterprise">
        <v-card>
          <v-card-title>Add enterprise</v-card-title>
          <v-card-text>
            <v-text-field
              label="Enterprise name"
              v-model="add_enterprise_name"
              placeholder="ex. Google"
              v-if="show"
              :disabled="l_loading"
              autofocus
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text type="button" @click="show = false">cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" :disabled="l_loading" type="submit">add</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { storage } from "../schmucklicloud";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      add_enterprise_name: "",
      l_loading: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    show(open) {
      if (open) {
        this.l_loading = false;
      }
    }
  },
  methods: {
    async addEnterprise(e) {
      e.preventDefault();
      this.l_loading = true;
      var response = await storage.insert("enterprise", {
        name: this.add_enterprise_name,
        archived: "",
      });
      if (response.isOK) {
        this.$notify.toast("The enterprise has been added and selected.");
        localStorage.setItem("enterprise", response.data.id);
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else {
        this.$notify.toast(response.message);
      }
    },
  },
};
</script>

<style>
</style>