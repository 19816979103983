import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import notify from './plugins/vuetify-notify';

import Tile from "./components/Tile";

Vue.config.productionTip = false

Vue.use(notify, {
  vuetify,
  options: {
    toast: {
      x: "right",
      y: "bottom",
      color: "accent"
    },
    dialog: {
      width: 400
    }
  }
});

Vue.component("Tile", Tile);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
