<template>
  <div>
    <v-dialog v-model="show" max-width="600px">
      <v-card>
        <v-card-title>Profile</v-card-title>
        <v-card-text style="text-align: left">
          <p>Logged in as: {{ email }}</p>
          <h3>Settings</h3>
          <v-text-field
            v-model="hours_per_week"
            label="Hours per week"
            placeholder="ex. 41"
            @change="saveSettings"
            style="width: calc(200px - 10px); display: inline-block"
          ></v-text-field>
          <span>h</span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            type="button"
            color="red"
            @click="logout"
            style="margin-top: 10px"
            >logout</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            type="button"
            @click="show = false"
            style="margin-top: 10px"
            >close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth, storage } from "../schmucklicloud";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: "",

      hours_per_week: 0,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    show(open) {
      if (open) {
        this.loadProfile();
      }
    },
  },
  mounted() {
    this.loadProfile();
    this.loadSettings();
  },
  methods: {
    async loadProfile() {
      var session_token = window.localStorage.getItem("_session_token");
      if (session_token) {
        var response = await auth.getUserDetails(session_token);
        if (response.isOK) {
          this.email = response.data.email;
        } else {
          this.$notify.toast("Something went wrong. Please sign in again.");
          this.logout();
        }
      }
    },
    async loadSettings() {
      var response = await storage.getAll("settings");
      if (response.isOK) {
        if (response.data) {
          this.hours_per_week = response.data[0].data.hours_per_week;
        }
      }
    },
    async saveSettings() {
      var settings = {
        hours_per_week: this.hours_per_week
      };

      var response = await storage.getAll("settings");
      if (response.isOK) {
        if (response.data) { // Update the data
          var row_id = response.data[0].id;
          var response_update = await storage.update("settings", row_id, settings);
          if (response_update.isOK) {
            this.$notify.toast("The settings has been saved.");
          } else {
            this.$notify.toast(response.message);
          }
        } else { // Insert the data
          var response_insert = await storage.insert("settings", settings);
          if (response_insert.isOK) {
            this.$notify.toast("The settings has been saved.");
          } else {
            this.$notify.toast(response.message);
          }
        }
      }
    },
    async logout() {
      var session_token = window.localStorage.getItem("_session_token");
      await auth.logout(session_token);
      window.localStorage.removeItem("_session_token");
      this.$router.replace("/");
      window.location.reload();
    },
  },
};
</script>

<style>
</style>