var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_vm._v("Enterprises")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.tab_enterprises,"headers":[
            {
              text: 'Name',
              value: 'name',
            },
            {
              text: 'Archived',
              value: 'archived',
              width: '30%',
            } ]},on:{"click:row":function (item) { _vm.enterprise_dialog = true; _vm.enterprise_dialog_item = { id: item.id, name: item.name, archived: item.archived === '1' } }},scopedSlots:_vm._u([{key:"item.archived",fn:function(ref){
          var item = ref.item;
return [_c('i',{staticClass:"fas fa-circle",style:({ color: item.archived ? 'grey' : 'green' })}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(!item.archived ? "Active" : "Archived"))])]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{staticStyle:{"margin-top":"10px"},attrs:{"text":"","type":"button"},on:{"click":function($event){_vm.show = false}}},[_vm._v("close")]),_c('v-spacer'),_c('v-btn',{staticStyle:{"margin-top":"10px"},attrs:{"text":"","color":"primary","type":"button"},on:{"click":function($event){_vm.enterprise_add_dialog = true}}},[_vm._v("add")])],1)],1)],1),_c('EditEnterpriseDialog',{attrs:{"currentItem":_vm.enterprise_dialog_item},model:{value:(_vm.enterprise_dialog),callback:function ($$v) {_vm.enterprise_dialog=$$v},expression:"enterprise_dialog"}}),_c('AddEnterpriseDialog',{model:{value:(_vm.enterprise_add_dialog),callback:function ($$v) {_vm.enterprise_add_dialog=$$v},expression:"enterprise_add_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }